@import '../core/mixins/border-radius';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';
@import '../core/mixins/flexbox';
.app-c-grid {
    height: 100%;
    min-width: 0;
    background-color: #f2f4f7 !important;
    @include flexbox();
    @include flex-direction(column);
    .ag-root-wrapper {
        border: none !important;
        background: transparent;
    }
    .ag-header {
        background: $white !important;
        border-color: rgb(217, 225, 233) !important;
    }
    .ag-header-row {
        &:hover,
        &:focus {
            .ag-header-cell {
                .ag-header-cell-resize {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .ag-header-cell {
        padding-left: 16px !important;
        padding-right: 16px !important;
        .ag-header-cell-resize {
            &:after {
                opacity: 0;
                @include transition(opacity 0.2s ease);
            }
        }
    }
    .ag-header-cell-text {
        color: rgba(28, 40, 52, 0.6);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: normal;
    }
    .ag-row {
        border-color: rgb(217, 225, 233) !important;
    }
    .ag-row-invalid {
        border-color: red !important;
    }
    .ag-row-odd {
        background: none !important;
    }
    .ag-row-hover {
        background: #f8f9fb !important;
    }
    .ag-row-selected {
        background: #f8f9fb !important;
    }
    .app-exported-reprocess-loans {
        background: #ffb6c1 !important;
    }
    .app-treasury-loan-updates {
        background: #e6f3ff !important;
    }
    .app-exported-loans {
        background: #e4e4e4 !important;
    }
    .ag-cell {
        color: $text-color;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 25px;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .ag-has-focus {
        .ag-cell:not(.ag-cell-last-left-pinned) {
            border: none !important;
            line-height: 25px !important;
        }
    }
    .ag-paging-panel {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        .ag-paging-row-summary-panel span {
            color: rgba(28, 40, 52, 0.6);
            font-family: $font-primary;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.25px;
            line-height: 15px;
            .ag-paging-row-summary-panel-number {
                color: rgba(28, 40, 52, 0.6);
                font-family: $font-primary;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.25px;
                line-height: 15px;
            }
        }
        .ag-paging-page-summary-panel {
            margin-left: auto;
            .ag-paging-button,
            .ag-paging-button-wrapper {
                margin: 0;
                padding: 4px;
                min-height: 24px;
                min-width: 24px;
                @include border-radius(4px);
                &:hover,
                &:focus {
                    background: rgb(228, 233, 239);
                }
                &.ag-disabled {
                    cursor: not-allowed;
                    &:hover,
                    &:focus {
                        background: transparent;
                    }
                    .ag-icon {
                        color: rgba(28, 40, 52, 0.4);
                    }
                }
                .ag-icon {
                    color: rgba(28, 40, 52, 0.6);
                    font-size: 16px;
                }
            }
            .ag-paging-description span {
                color: rgba(28, 40, 52, 0.6);
                font-family: $font-primary;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.25px;
                line-height: 17px;
                text-align: center;
                .ag-paging-number {
                    color: rgba(28, 40, 52, 0.6);
                    font-family: $font-primary;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    line-height: 17px;
                    text-align: center;
                }
            }
        }
    }
    .ag-theme-balham {
        .ag-checkbox-input-wrapper {
            &:after {
                color: rgb(217, 225, 233);
                @include border-radius(4px);
            }
            &.ag-checked {
                &:after {
                    color: $app-brand-primary;
                }
            }
        }
        button {
            &.ag-side-button-button {
                font-family: $font-primary;
                -webkit-font-smoothing: subpixel-antialiased;
            }
        }
        .ag-root-wrapper {
            background-color: transparent !important;
            .ag-root-wrapper-body {
                .ag-root {
                    .ag-body-viewport {
                        background: $white;
                    }
                }
                .ag-side-bar-right {
                    background-color: $white;
                    border-left-color: rgb(217, 225, 233) !important;
                    .ag-side-buttons {
                        .ag-side-button {
                            .ag-side-button-button {
                                .ag-side-button-icon-wrapper {
                                    .ag-icon {
                                        font-family: 'loandepot' !important;
                                        font-size: 12px;
                                        color: rgba(28, 40, 52, 0.6);
                                        &.ag-icon-filter {
                                            &:before {
                                                content: '\e90f';
                                            }
                                        }
                                        &.ag-icon-columns {
                                            &:before {
                                                content: '\e91e';
                                            }
                                        }
                                    }
                                }
                                .ag-side-button-label {
                                    font-family: $font-primary;
                                    color: rgba(28, 40, 52, 0.6);
                                    font-size: 14px;
                                    font-weight: 600;
                                    letter-spacing: 0.25px;
                                    line-height: 15px;
                                }
                            }
                        }
                    }
                    button {
                        &.ag-side-button-button {
                            font-family: $font-primary;
                        }
                    }
                }
            }
        }
    }
    .app-c-grid__pagination-custom {
        position: relative;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        width: 100%;
        margin-top: 32px;
        .grid-pagination__viewing-info {
            position: absolute;
            left: 0px;
            span {
                color: rgba(28, 40, 52, 0.6);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.25px;
                line-height: normal;
            }
        }
        .grid-pagination__action {
            .app-c-btn--icon {}
        }
    }
    &.app-c-grid--with-color-indication {
        .ag-row {
            .ag-cell {
                &:first-child {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    border: none !important;
                }
            }
        }
        .ag-header-row {
            .ag-header-cell {
                &:first-child {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 10px !important;
                    border: none !important;
                }
            }
        }
    }
    &.app-c-conditions-grid {
        position: relative !important;
        z-index: 1058;
        .ag-row {
            .ag-cell {
                @include inline-flex();
                @include align-items(center);
                @include justify-content(flex-start);
            }
        }
        .app-l-custom-wrapper {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0; //change to switch case
            .app-l-left-side {
                display: flex;
                flex-direction: column;
                .app-c--description {
                    border-top: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-left: 1px solid transparent;
                    border-bottom: 1px solid #919bab;
                }
                .app-c-label {
                    color: rgba(28, 40, 52, 0.8);
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0.27px;
                }
                .form-control {
                    border-top: 1px solid transparent !important;
                    border-right: 1px solid transparent !important;
                    border-left: 1px solid transparent !important;
                    border-bottom: 1px solid #919bab !important;
                }
            }
            .app-l-middle-side {
                display: flex;
                flex-direction: column;
                .app-c-label {
                    color: rgba(28, 40, 52, 0.8);
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0.27px;
                }
            }
            .app-l-right-side {
                display: flex;
                flex-direction: column;
                .app-c-label {
                    color: rgba(28, 40, 52, 0.8);
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0.27px;
                }
            }
        }
    }
    &.app-l-grid__cnd-search {
        border: 1px solid transparent !important;
        .ag-checkbox {
            position: relative;
            .ag-checkbox-input-wrapper {
                overflow: hidden;
                @include border-radius(3px);
                &:after {
                    background: rgb(206, 211, 216);
                    position: absolute;
                    top: 13.5px;
                    left: 0px;
                    opacity: 0.3;
                }
                &:focus {
                    color: rgb(17, 127, 201);
                }
            }
        }
    }
    // Icons
    .ag-icon-desc,
    .ag-icon-asc {
        font-family: 'loandepot' !important;
    }
    .ag-icon-desc:before {
        content: '\e910' !important;
    }
    .ag-icon-asc:before {
        content: '\e912' !important;
    }
}

.app-l-conditions-grid {
    width: 100%;
    min-width: 0;
    height: 100%;
    min-height: 0;
    @include flexbox();
    @include flex-direction(column);
    &.app-l-tab-extend {
        .mat-tab-group {
            .mat-tab-header {
                margin: 0 32px 0 0;
            }
        }
    }
    .mat-tab-group {
        height: 100%;
        min-height: 0;
        @include flexbox();
        @include flex-direction(column);
        .mat-tab-body-wrapper {
            height: 100%;
            min-height: 200px;
            @include flexbox();
            @include flex-direction(column);
            .mat-tab-body-content {
                height: 100%;
                min-height: 0;
                @include flexbox();
                @include flex-direction(column);
            }
        }
    }
    .mat-tab-body {
        &.mat-tab-body-active {
            overflow: visible;
        }
    }
}

.app-l-conditions-grid {
    width: 100%;
    min-width: 0;
    height: 100%;
    min-height: 0;
    position: relative;
    @include flexbox();
    @include flex-direction(column);
    &.app-l-tab-extend {
        .mat-tab-group {
            .mat-tab-header {
                margin: 0 32px 0 0;
            }
        }
    }
    .mat-tab-group {
        height: 100%;
        min-height: 0;
        @include flexbox();
        @include flex-direction(column);
        .mat-tab-body-wrapper {
            height: 100%;
            min-height: 0;
            @include flexbox();
            @include flex-direction(column);
            .mat-tab-body-content {
                height: 100%;
                min-height: 0;
                @include flexbox();
                @include flex-direction(column);
            }
        }
    }
    .app-l-tab-options {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        min-width: 32px;
        min-height: 49px;
        @include flexbox();
        .app-l-tab-option-holder {
            height: 100%;
            width: 100%;
            @include flexbox();
            @include align-items(center);
            .app-l-tab-option {
                width: 32px;
                height: 39px;
                padding: 4px;
                margin: 5px 0px;
                background: transparent;
                border: solid 1px transparent;
                outline: none;
                cursor: pointer;
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);
                @include border-radius(4px);
                @include transition(background 0.2s ease);
                &:hover {
                    background-color: $app-brand-primary;
                    i {
                        color: $white;
                    }
                }
                &.disabled {
                    background: none;
                    opacity: 0.6;
                    cursor: not-allowed;
                    i {
                        color: $app-brand-primary;
                        cursor: inherit;
                    }
                }
                i {
                    font-size: 16px;
                    line-height: normal;
                    color: $app-brand-primary;
                    cursor: pointer;
                    @include transition(background 0.2s ease);
                }
            }
            .app-l-option-dropdown {}
        }
    }
}

.app-l-grid-widget {
    height: 100%;
    width: 100%;
    min-width: 0;
    @include flexbox();
    @include flex-direction(column);
}

.app-l-feature-grid {
    height: 100%;
    min-height: 0;
    width: 100%;
    min-width: 0;
    position: relative;
    z-index: 0;
    @include flexbox();
    @include flex-direction(column);
    .app-l-grid-widget {
        z-index: 1;
    }
}

.app-l-grid-features {
    position: absolute;
    z-index: 2;
    @include flexbox();
    @include align-items(center);
    &.positioned {
        top: 14px;
        right: 8px;
        bottom: auto;
        left: auto;
    }
    .app-l-grid-features-wrap {
        margin: -8px;
        padding: 0;
        min-width: 0;
        @include flexbox();
        @include align-items(center);
        .app-l-grid-features-btn {
            padding: 8px;
            min-width: 18px;
            min-height: 18px;
            outline: none;
            position: relative;
            line-height: 0;
            font-size: 0;
            cursor: pointer;
            background-color: transparent;
            border: solid 1px transparent;
            outline: 0;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            @include transition(background 0.2s ease-in-out);
            &:hover,
            &:focus {
                >i {
                    opacity: 1;
                }
            }
            >i {
                font-size: 17px;
                line-height: normal;
                cursor: pointer;
                color: $text-color;
                opacity: 0.7;
                @include transition(color 0.2s ease-in-out, opacity 0.2s ease-in-out);
            }
        }
    }
}

.app-l-grid-control {
    position: absolute;
    top: 0px;
    right: 42px;
    z-index: 1;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .app-l-grid-control--btn {
        width: 32px;
        height: 39px;
        padding: 4px;
        margin: 5px 0px;
        background: transparent;
        border: solid 1px transparent;
        outline: none;
        cursor: pointer;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include border-radius(4px);
        @include transition(background 0.2s ease);
        &:hover {
            background-color: $app-brand-primary;
            i {
                color: $white;
            }
        }
        +.app-l-grid-control--btn {
            margin-left: 10px;
        }
        &.disabled {
            background: none;
            opacity: 0.6;
            cursor: not-allowed;
            i {
                color: $app-brand-primary;
                cursor: inherit;
            }
        }
        i {
            font-size: 16px;
            line-height: normal;
            color: $app-brand-primary;
            cursor: pointer;
            @include transition(background 0.2s ease);
        }
    }
}

.app-c-grid-selected-conditions {
    height: 280px;
}

.app-aggregation-grid .ag-row, .app-aggregation-grid .ag-header-container {
    border: 1px solid #d9e1e9 !important;
}

.app-aggregation-grid .ag-row {
  background-color: transparent !important;
}

.app-aggregation-grid .ag-body-viewport {
  background-color: transparent !important;
}

.app-c-grid .ag-row-odd
{
  background: none;
}

.app-aggregation-grid .ag-header {
  background-color: transparent !important;
  border-bottom: none;
  font-weight: bold;
}
.app-aggregation-grid .ag-header-cell-text{
  font-weight: bold;
  color: #1c2834;
}
.app-aggregation-grid .ag-root-wrapper-body {
  border: none !important;
}
