.mat-mdc-tab {
  min-width: 70px !important;
  padding: 0 16px !important;
  position: relative;
  opacity: 1 !important;
  font-family: $font-primary !important;

  .cdk-drag {
    &:hover,
    &:focus {
      .mdc-tab__text-label {
        color: $app-brand-primary;
      }

      .app-l-tab-setting {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .app-l-tab-setting {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 16px;
    height: 16px;
    opacity: 0;
    visibility: hidden;
    border: solid 1px transparent;
    background: transparent;
    margin: 0;
    padding: 2px;
    outline: none;
    cursor: pointer;

    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include border-radius(50%);
    @include transition(
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      background-color 0.2s ease-in-out
    );

    &:hover,
    &:focus {
      background-color: $white;
    }

    > i {
      font-size: 10px;
      color: $app-brand-primary;
    }
  }
}
.mdc-tab__text-label {
  color: $text-color;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: normal;
  text-decoration: none;
  outline: none;
  border: none;

  @include transition(color 0.3s ease-in-out);

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
  }
}
.mat-tab-label-active {
  .mdc-tab__text-label {
    color: $app-brand-primary;
  }
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $app-brand-primary;
}
.mat-tab-group {
  &.mat-primary {
    &.app-l-dragable-tab {
      .mat-ink-bar {
        background-color: transparent !important;
      }
    }
  }
}
.mat-tab-header {
  border-bottom: 1px solid rgb(217, 225, 233);
}

.mat-menu-panel {
  background: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  margin-top: 6px;
  width: 255px;
  margin-left: 10px;
  margin-bottom: -37px;
  .mat-menu-content {
    @include border-radius(4px);
    padding: 0px !important;
  }
  .mat-menu-item {
    padding: 16px 0px;
    height: auto;
    line-height: normal;
    &:focus {
      outline: none;
      border: none;
    }
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
    }
    + .mat-menu-item {
      border-top: 1px solid #d8e1ea;
      padding-top: 12px;
    }
    .menu-item-heading {
      @include flexbox();
      @include align-items(center);
      // margin-bottom: 12px;
      i,
      span {
        font-weight: normal;
        font-size: 15px;
        line-height: normal;
        color: #777f86;
      }
      span {
        margin-left: 10px;
      }
    }
    .app-l-header__user__menu-item-content {
      @include flexbox();
      @include align-items(center);
      .menu-item-attribute {
        font-weight: normal;
        font-size: 15px;
        line-height: normal;
        color: #767e85;
        margin-right: 20px;
      }
      a {
        font-weight: normal;
        font-size: 15px;
        line-height: normal;
        color: $app-brand-primary;
      }
      + .app-l-header__user__menu-item-content {
        margin-top: 8px;
      }
    }
  }
  .app-l-header__user__info {
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $app-brand-primary;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    h6 {
      font-weight: normal;
      font-size: 15px;
      line-height: normal;
      color: #414c57;
    }
  }
  &.app-l-head-menu {
    .mat-menu-content {
      padding: 0px;

      @include border-radius(4px);
    }

    .app-l-user-info-wrap {
      padding: 13px 17px;
      width: 100%;
      cursor: pointer;
      outline: none;
      background: transparent;
      border: none;

      @include flexbox();
      @include flex-direction(column);
      @include transition(background 0.3s ease-in-out);

      &:hover {
        background: #f1f1f1;

        .app-l-username {
          > h3 {
            color: #000000;
          }
        }
      }

      &:focus {
        .app-l-username {
          > h3 {
            color: #000000;
          }
        }
      }

      .app-l-user-avatar {
        width: 100%;

        @include flexbox();
        @include justify-content(flex-start);
        @include align-items(center);

        .app-l-avatar-wrap {
          min-width: 26px;
          min-height: 32px;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          > i {
            font-size: 16px;
            line-height: normal;
            margin: auto auto auto 0;
            cursor: pointer;
            color: rgba(28, 40, 52, 0.8);
          }
        }

        .app-l-username {
          width: 100%;
          padding: 0;

          @include flexbox();
          @include align-items(center);

          > h3 {
            color: rgba(28, 40, 52, 0.8);
            font-family: $font-primary;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 20px;
            margin: 0;

            @include transition(color 0.3s ease-in-out);
          }
        }
      }
    }
  }
}

.mat-accordion {
  .mat-expansion-panel {
    margin-bottom: 0 !important;

    @include boxShadow(0 0 0 0 transparent);

    &.cursor-pointer {
      &:hover,
      &:focus {
        background-color: #f1f1f1 !important;
        background: #f1f1f1 !important;
      }
    }

    .mat-expansion-panel-header {
      &:hover,
      &:focus {
        background-color: #f1f1f1 !important;
        background: #f1f1f1 !important;
      }
    }
  }
}

.mat-select-panel {
  background-color: $white;
  background: $white;
}

.mat-form-field {
  &.mat-focused {
    .mat-form-field-ripple {
      background-color: $app-brand-primary;
    }
    .mat-form-field-label {
      color: $app-brand-primary;
    }

    &.mat-primary {
      .mat-select-arrow {
        color: $app-brand-primary;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.mat-select-arrow {
  width: 16px !important;
  height: 16px !important;
  border: none !important;
  position: relative;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  &:before {
    content: '\e900';
    color: #1c2834;
    font-size: 12px;
    line-height: normal;
    font-family: 'loandepot' !important;
    cursor: pointer;
    //opacity: 0;
  }
}

.app-l-loading-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.85);

  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  .app-l-login-loading {
    min-width: 0;
    width: 100%;
    min-height: 0;
    height: 100%;
    position: relative;

    @include flexbox();
    @include justify-content(center);
    @include align-items(center);

    .mat-progress-bar {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
    }
  }
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(28, 40, 52, 0.8) !important;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: rgba(28, 40, 52, 0.8) !important;
}
