@import '../core/mixins/box-shadow';
@import '../core/mixins/flexbox';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';
@import '../core/mixins/border-radius';

@import '../variables/variables';
@import '../theme/theme';

.app-c-tab-group {
  background-color: $white;

  .tab-container {
    width: auto;

    .nav-tabs {
      border-bottom-color: $generic-border-color;

      .nav-item {
        a {
          padding: 12px 24px;
          position: relative;
          border: none;
          background-color: transparent;
          font-weight: 500;
          font-size: 15px;
          line-height: 17px;
          color: $text-dark-normal;

          @include transition(color 0.3s ease-in-out);

          &:hover {
            color: $text-dark-extra;
          }

          &:before {
            content: '';
            width: 0;
            height: 3px;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background-color: $app-brand-primary;

            @include transition(width 0.3s ease-in-out);
            @include border-radius(20px 20px 0px 0px);
          }
        }
        &.active {
          a {
            color: $text-dark-extra;

            &:before {
              width: 51px;
            }
          }
        }
      }
    }
  }

  &.horizontal-scroll {
    .tab-container {
      .nav-tabs {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        @include flex-wrap(nowrap);
      }
    }
  }
}

.mat-tab-group {
  .mat-tab-header {
    .mat-tab-header-pagination {
      min-width: 30px;

      @include transition(background-color 0.3s ease-in-out);

      &:hover {
        background-color: $white;
      }

      &.mat-tab-header-pagination-disabled {
        &:hover {
          background-color: transparent;
        }

        .mat-tab-header-pagination-chevron {
          border-color: $text-color;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &.mat-elevation-z4 {
        @include noShadow();
      }

      .mat-tab-header-pagination-chevron {
        border-color: $text-color;
      }
    }
  }
}

.app-l-panel-tabs {
  .mat-tab-group {
    .mat-tab-header {
      background-color: $white;

      @include flex-shrink(0);
      @include boxShadow(0px 1px 0px 0px rgba(28, 40, 52, 0.2));

      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            padding: 0;

            @include align-items(center);
            @include justify-content(center);

            .mat-mdc-tab {
              opacity: 1 !important;
              padding: 0 !important;
              font-family: $font-primary !important;
              &.mat-tab-label-active {
                .mdc-tab__text-label {
                  .app-l-panel-tab-symbol {
                    > i {
                      color: $app-brand-primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      height: 100%;
      min-height: 0;
      width: 100%;
      min-width: 0;

      @include flexbox();
      @include flex-direction(column);

      .mat-tab-body {
        height: 100%;
        min-height: 0;
        width: 100%;
        min-width: 0;
      }
    }
  }
}
