.red-snackbar  .mat-mdc-snackbar-surface {
    background-color: #ea3d2f !important;
    color: white !important;
  }

  .red-snackbar span {
    color: white !important;
  }

  .warning-snackbar  .mat-mdc-snackbar-surface{
    background-color: #ff9800 !important;
    color: white !important;
  }

  .warning-snackbar span {
    color: white !important;
  }

  .info-snackbar  .mat-mdc-snackbar-surface{
    background-color: #2196f3 !important;
    color: white !important;
  }

  .info-snackbar span {
    color: white !important;
  }

  .success-snackbar  .mat-mdc-snackbar-surface {
    background-color: #4caf50 !important;
    color: white !important;
  }

  .success-snackbar span {
    color: white !important;
  }
