// __<<ngThemingMigrationEscapedComment0>>__

// @import 'scss/main';
@import 'libs/ui-kit/styles/main';

.ag-row{
  font-family: $font-primary !important;
  // @include ag-theme-balham(
  //   (
  //     odd-row-background-color: #ff44,
  //   )
  // );
}

.ag-theme-balham .ag-row-odd {
  background-color: #ff44,
}

.imageicon {
  width: 5px !important;
  height: 5px !important;
}

.tagicon {
  height: 15px !important;
  width: 15px;
  padding-top: 15px !important;
  margin-top: 0px !important;
  position: fixed !important;
}

.tag {
  height: 100% !important;
  width: 8px;
  padding-top: 0px !important;
  margin-top: 0px !important;
  position: fixed !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.32) !important;
}

@media only screen and (max-width: 640px) {
  .mat-dialog-container {
    width: 10em !important;
  }
}
.tag2 {
  border: 0.7px solid white;
  height: 25px;
  width: 15px;
  padding-top: 0px !important;
  margin: 0px !important;
  position: relative;
}
.color11 {
  background-color: blue;
  border-radius: 4px 0px 0px 0px;
}
.color1 {
  background-color: blue;
}
.color2 {
  background-color: red;
}
.color3 {
  background-color: rgb(23, 187, 23);
}
.color4 {
  background-color: rgb(238, 12, 219);
}
.color5 {
  background-color: yellow;
}
.color6 {
  background-color: rgb(75, 216, 241);
}
.divposition {
  position: absolute;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
::ng-deep.mat-dialog-container {
  padding: 0px !important;
  background-color: palegoldenrod !important;
}

span {
  color: black;
}

.mat-dialog-container {
  background: #fff !important;
  display: flex;
  padding-top: 0px !important;
  padding-left: 0px !important;
  position: fixed !important;
  pointer-events: auto;
  left: 7px;
  top: auto;
  bottom: 30px;
  width: 10em !important;
  height: auto !important;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction
       and if we allow the items to wrap
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
  flex-direction: column;

  /* Then we define how is distributed the remaining space */
  justify-content: space-around;

  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  flex: 1 0 auto !important;
}

.items {
  justify-content: left;
  padding-top: 0px !important;
  width: auto;
  height: auto;
}
.truecolor {
  color: cornsilk;
  font-size: 35px;
}

.itemstyle {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 30vw;
  height: 30vh;
}

.ag-theme-balham .ag-cell {
  padding-left: 0px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font-primary !important;
}

.app-l-access-denied {
  min-height: 0;
  height: 100%;
  min-width: 0;
  width: 100%;

  @include flexbox();
  @include flex-direction();

  .app-l-content-data {
    min-height: 0;
    height: 100%;
    min-width: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @include flexbox();
    @include flex-direction();

    .app-l-content-design {
      min-height: 0;
      height: auto;
      width: 100%;

      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);

      .app-l-content-text {
        min-height: 0;
        height: auto;

        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);

        .app-l-no-access-head {
          font-style: normal;
          font-weight: 300;
          font-size: 37px;
          line-height: 47px;
          text-align: center;
          color: #1c2834;
          margin: 0;

          + .app-l-no-access-sub-text {
            margin-top: 16px;
          }
        }

        .app-l-no-access-sub-text {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #1c2834;
        }

        .app-l-no-access-text {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #1c2834;
        }

        .app-l-btn-wrap {
          margin-top: 32px;
          max-width: 100%;

          @include flexbox();
        }

        .app-l-btn-group {
          margin: -8px;
          max-width: 100%;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          @media screen and (max-width: 480px) {
            @include flex-wrap(wrap);
          }

          .app-l-btn-holder {
            min-width: 0;
            min-height: 0;
            margin: 8px;
            min-width: 140px;

            @include flex-shrink(0);

            .app-c-btn {
              min-width: 140px;
              min-height: 48px;
            }
          }
        }

        + .app-l-content-graphic {
          margin-top: 75px;
        }
      }

      .app-l-content-graphic {
        min-height: 0;
        height: auto;

        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);

        img {
          max-width: 328px;
          width: 328px;
          max-height: 240px;
          height: 240px;
        }
      }
    }
  }
}
.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  cursor: pointer !important;
  background-color: rgb(248, 248, 249) !important;
}

.ag-row-selected {
  cursor: pointer !important;
  background-color: rgb(243, 247, 251) !important;
}

.ag-row-hover {
  &.ag-row-selected {
    cursor: pointer !important;
    background-color: rgb(243, 247, 251) !important;
  }
}

.app-c-not-found {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  min-height: 200px;
  background: $white;
  img {
    max-width: 100px;
  }
  .app-c-not-found__text {
    margin-top: 6px;
    p {
      margin-bottom: 0px;
      color: rgb(28, 40, 52);
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.25px;
      line-height: normal;
      padding-bottom: 0px;
    }
  }
}

.app-l-template-controls {
  width: 100%;
  margin: 8px;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  .app-l-template-btn-group {
    min-width: 0;
    min-height: 0;
    margin: -8px;

    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);

    .app-l-btn-holder {
      margin: 4px;
      min-width: 134px;

      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include flex-shrink(0);
    }
  }
}

.cdk-overlay-container {
  z-index: 999999 !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.tab-drag-list {
  max-width: 100%;
  border: solid 1px transparent;
  min-height: 49px;
  background: transparent;
  border-radius: 4px;
  overflow: hidden;

  @include flexbox();
  @include align-items(center);
  @include flex-shrink(0);
}

.tab-drag-entry {
  padding: 6px 10px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  background: transparent;
  font-size: 14px;

  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-shrink(0);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.tab-drag-entry:last-child {
  border: none;
}

.tab-drag-list.cdk-drop-list-dragging
  .tab-drag-entry:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.your-css-class {
  display: block;
}

.disable-column-tool-panel {
  opacity: 0.6 !important;
}

.app-l-dragable-tab {
  &.mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            .mat-mdc-tab {
              &.mat-tab-label-active {
                width: 100%;
                padding: 0 !important;

                .mdc-tab__text-label {
                  width: 100% !important;
                  .cdk-drop-list {
                    width: 100% !important;
                    min-height: 48px;
                    .search-tab {
                      background-color: white;
                    }

                    .cdk-drag {
                      position: relative;
                      z-index: 0;
                      color: rgb(28, 40, 52);
                      min-height: 48px;

                      &:before {
                        content: '';
                        width: 100%;
                        height: 2px;
                        z-index: 2;
                        position: absolute;
                        top: auto;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: transparent;
                      }

                      &.active {
                        background-color: transparent;
                        color: $app-brand-primary;

                        &:before {
                          background-color: $app-brand-primary;
                        }

                        .mdc-tab__text-label {
                          color: $app-brand-primary;

                          &:hover,
                          &:focus {
                            outline: none;
                            text-decoration: none !important;
                          }
                        }
                      }

                      .mdc-tab__text-label {
                        color: $text-color;
                        background: transparent;
                        border: none;
                        outline: none;

                        &:hover,
                        &:focus,
                        &:active {
                          outline: none !important;
                        }
                      }

                      &:before {
                        background-color: transparent;
                      }
                    }
                  }
                }

                .mat-ink-bar {
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.disabledarea {
  pointer-events: none;
  opacity: 0.6;
  background-color: gray;
}
.image-item {
  padding-right: 10px;
  min-width: 48px;
  height: 19px;
}
.separation {
  font-size: 30px;
  margin-top: -4px;
  margin-bottom: 4px;
  color: #8c5ac2;
  font-weight: 300;
}
.popdown {
  width: 60px;
  height: auto;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-right: 1px;
  min-width: 12rem;

  &:active {
    color: rgb(131, 85, 186) !important;
    text-transform: uppercase;
    background-color: transparent !important;
  }
  .app-c-dropdown-item {
    display: flex;
    align-items: center;
    white-space: normal !important;
    padding: 0.5rem 0.5rem !important;
    &:active {
      background-color: transparent !important;
    }
    cursor: pointer;
  }
}

.drop-down-active {
  color: rgb(131, 85, 186);
  font-size: 12px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-transform: uppercase;
}

.drop-down-hover {
  color: rgba(28, 40, 52, 0.4);
}
.btntick {
  font-size: 15px;
  color: #2c3e50;
  margin-bottom: 15px;
}
.btnsize {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.messaging {
  position: relative;
  .app-c-red-circle {
    position: absolute;
    right: -3px;
    top: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background: #d2414f;
  }
}

.linking {
  position: relative;
  font-size: 25px;
  .app-c-blue-circle {
    position: absolute;
    right: -3px;
    top: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background: #0d6bb8;
  }
}
.button-c-m-margin-top {
  padding-top: 12px !important;
}
.icon-c-m-padding-b {
  padding-bottom: 9px;
}
.ag-cell {
  border-left: 1px solid transparent !important;
  border-left-color: transparent !important;
  border-left-color: var(--ag-border-color, transparent) !important;
}

.ag-theme-balham .ag-pinned-right-header {
  border-left: 2px solid !important;
  border-left-color: transparent !important;
  border-left-color: var(--ag-border-color, transparent) !important;
}

// .ag-theme-balham .ag-pinned-left-header {
//   width: 40px !important;
//   max-width: 40px !important;
//   min-width: 40px !important;
//   .ag-header-row-column {
//     width: 100% !important;
//     .ag-header-cell {
//       left: 0px !important;
//     }
//   }
// }

.btntick {
  font-size: 15px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.btnsize {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.messaging {
  position: relative;
  margin-right: 10px;
  .app-c-red-circle {
    position: absolute;
    right: -3px;
    top: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background: #d2414f;
  }
}
​​​​​​​​ .linking {
  position: relative;
  font-size: 25px;
  .app-c-blue-circle {
    position: absolute;
    right: -3px;
    top: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background: #0d6bb8;
  }
}

.app-c-line {
  margin-right: 10px;
  color: #e4e9ef;
  font-size: 29px;
  padding-left: 10px;
}
.app-c-l-name-hover {
  &:hover,
  &:focus {
    text-decoration: underline;
    color: #0d6bb8;
    cursor: pointer;
  }
}

.icon-reset {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.app-c-icon-folder {
  height: 100px;
  width: 100px;
}

.ag-theme-balham {
  &.ld-manager-grid {
    .color-tag-renderer {
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .color-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
    }
    .color-item {
      border: 1px solid transparent;
      width: 100%;
      flex: 1;
      border-radius: 40px;
      + .color-item {
        margin-top: 2px;
      }
      &.more-icon {
        max-height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.no-tag {
        background: transparent;
        border-radius: 4px;
        border: 1px solid #c0bfcb;
      }
    }

    .color-cell {
      padding: 4px 2px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    .ag-cell-wrapper {
      &.ag-row-group {
        align-items: center;
      }
    }
  }
}
.app-c-icon-folder {
  height: 100px;
  width: 100px;
}

.app-c-tab-btn-refresh {
  display: flex;
  justify-content: flex-end;
  margin-right: 118px;
}

.bs-date-picker-theme {
  background-color: $app-brand-primary;
}
.resultGrid {
  .ag-root{
    height: auto !important
  }
}
.ag-root-wrapper-body.ag-layout-normal {
  border: 1px solid #e9e9e9;
  flex: 1;
  height: 0;
  min-height: 0;
}

.bs-timepicker-field {
  width: 28px !important;
  padding: 0.1rem 0.1rem !important;
}

timepicker {
  table {
    tbody {
      tr {
        td {
          input,
          button {
            min-height: 24px !important;
            height: 24px !important;
            width: 28px !important;
            padding: 0.1rem 0.1rem !important;
            margin-top: 4px;
          }
          button {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

.whl-text-area {
  width: 350px;
}

bs-dropdown-container{
  z-index:1050 !important;
}

.spinneroverlay{
  height:100vh;
  width:100%;
  background-color:rgba(0, 0, 0, 0.286);
  z-index:    10;
  top:        0;
  left:       0;
  position:   fixed;
}

.spinnercenter
{
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $app-brand-primary-dark !important;
}

  .app-c-radio-group {
    display: flex;
    align-items: center;
    .app-c-radio-stnd-wrapper {
      margin-right: 16px;
      padding: 13px;
      border: 1px solid #d9e1e9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      .app-c-radio-stnd {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
    .app-c-radio-custm-wrapper {
      margin-right: 16px;
      padding: 13px;
      border: 1px solid #d9e1e9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      .app-c-radio-custm {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }

 .no-left {
	left: 0px !important
 }
